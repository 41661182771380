@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800&display=swap");
body{
  margin: 0 !important;
}
.flex{
  display: flex;
}
.flex-center{
  justify-content: center;
  align-items: center;
}
.flex-start{
  justify-content: flex-start;
  align-items: center;
}
.text-center{
  text-align: center;
}
.home-link{
  color: inherit;
  text-decoration: none;
}
.flex-space{
  justify-content: space-between;
  align-items: center;
}
.text-end{
  text-align: end;
}
.full-width{
  width: 100%;
}
.flex-column{
  flex-direction: column;
}
.file-card {
  display: inline-block;
  position: relative;
}
.file-input {
  display: none;
}
.card-label {
  display: block;
  padding: 16px;
  width: 136px;
  height: 120px;
  background: #FFFCF9 0% 0% no-repeat padding-box;
  border: 1px solid #FEF0E6;
  border-radius: 8px;
  cursor: pointer;
}
.card-content {
  display: flex;
  align-items: center;
}
.card-text {
  margin-left: 8px;
}
.tab-panel {
  margin-left: 0px !important;
  width: 90%;
}