@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800&display=swap);
.inputStyle {
    height: 55px;
    padding: 0 10px;
    border-radius: 7px;
    border: 1px solid;
    margin-left: 8px;
    margin-right: 8px;
    font-size: 22px;
}

.pendingOrder,
.acceptedOrder,
.pendingBill,
.verifiedBill,
.notVerifiedBill,
.rejectedBill,
.processedBill {
    position: relative;
    padding: 35px 20px 15px;
    overflow: unset !important;
}

.pendingOrder::before {
    position: absolute;
    content: 'New Order';
    top: 7px;
    left: -8px;
    padding: 4px;
    font-size: 12px;
    width: 7rem;
    background: #396773;
    color: white;
    text-align: center;
    font-family: 'Roboto', sans-serif;
    box-shadow: 4px 4px 15px rgba(26, 35, 126, 0.2);
    z-index: 1;
}

.pendingBill::before {
    position: absolute;
    content: 'Not Uploaded';
    top: 0px;
    left: -8px;
    padding: 4px;
    font-size: 12px;
    width: 7rem;
    background: #396773;
    color: white;
    text-align: center;
    font-family: 'Roboto', sans-serif;
    box-shadow: 4px 4px 15px rgba(26, 35, 126, 0.2);
    z-index: 1;
}

.verifiedBill::before {
    position: absolute;
    content: 'Verified';
    top: 0px;
    left: -8px;
    padding: 4px;
    font-size: 12px;
    width: 7rem;
    background: #396773;
    color: white;
    text-align: center;
    font-family: 'Roboto', sans-serif;
    box-shadow: 4px 4px 15px rgba(26, 35, 126, 0.2);
    z-index: 1;
}

.notVerifiedBill::before {
    position: absolute;
    content: 'Not Verified';
    top: 0px;
    left: -8px;
    padding: 4px;
    font-size: 12px;
    width: 7rem;
    background: #396773;
    color: white;
    text-align: center;
    font-family: 'Roboto', sans-serif;
    box-shadow: 4px 4px 15px rgba(26, 35, 126, 0.2);
    z-index: 1;
}

.rejectedBill::before {
    position: absolute;
    content: 'Rejected';
    top: 0px;
    left: -8px;
    padding: 4px;
    font-size: 12px;
    width: 7rem;
    background: #396773;
    color: white;
    text-align: center;
    font-family: 'Roboto', sans-serif;
    box-shadow: 4px 4px 15px rgba(26, 35, 126, 0.2);
    z-index: 1;
}
.processedBill::before {
    position: absolute;
    content: 'Processed';
    top: 7px;
    left: -8px;
    padding: 4px;
    font-size: 12px;
    width: 7rem;
    background: #396773;
    color: white;
    text-align: center;
    font-family: 'Roboto', sans-serif;
    box-shadow: 4px 4px 15px rgba(26, 35, 126, 0.2);
    z-index: 1;
}

.pendingOrder::after,
.pendingBill::after,
.verifiedBill::after,
.notVerifiedBill::after,
.rejectedBill::after,
.processedBill::after {
    position: absolute;
    content: '';
    background: #253F46;
    height: 25px;
    width: 6px;
    top: 12px;
    left: -8px;
    -webkit-transform: skewY(60deg);
            transform: skewY(60deg);
}
.addNewOrder {
    margin: 1rem;
    padding: 4px 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.submitNewOrder {
    display: block;
    margin: 1rem auto;
    color: #fff;
    border-color: #007bff;
    background-color: #007bff;
    cursor: pointer;
}

@media screen and (min-width:768px) {
    .cardContainer {
        width: 35rem;
    }

    .ordersContainer {
        width: 30rem;
        display: block;
        margin: auto;
    }
}

@media screen and (max-width:767px) {
    .materialList {
        width: 50px
    }
}
body{
  margin: 0 !important;
}
.flex{
  display: flex;
}
.flex-center{
  justify-content: center;
  align-items: center;
}
.flex-start{
  justify-content: flex-start;
  align-items: center;
}
.text-center{
  text-align: center;
}
.home-link{
  color: inherit;
  text-decoration: none;
}
.flex-space{
  justify-content: space-between;
  align-items: center;
}
.text-end{
  text-align: end;
}
.full-width{
  width: 100%;
}
.flex-column{
  flex-direction: column;
}
.file-card {
  display: inline-block;
  position: relative;
}
.file-input {
  display: none;
}
.card-label {
  display: block;
  padding: 16px;
  width: 136px;
  height: 120px;
  background: #FFFCF9 0% 0% no-repeat padding-box;
  border: 1px solid #FEF0E6;
  border-radius: 8px;
  cursor: pointer;
}
.card-content {
  display: flex;
  align-items: center;
}
.card-text {
  margin-left: 8px;
}
.tab-panel {
  margin-left: 0px !important;
  width: 90%;
}
